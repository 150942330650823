import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { Redirect } from 'react-router'
import Container, { Buttons } from 'components/Container'
import Button from 'components/Button'
import DefaultInput from 'components/Input'
import { InputGroup } from './styles'
import StripeCard from 'components/Stripe'
import { ReservationTotal, Title, Total, BlueText } from './styles'

yup.addMethod(yup.string, 'phone', function(message) {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: message || 'Please enter your phone number.',
    test: value => {
      return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-.]?[0-9]{4,6}$/im.test(
        value
      )
    }
  })
})

class Payment extends Component {
  state = { reservationComplete: false }

  onSubmit = async (values, { setSubmitting, setErrors }) => {
    const {
      createReservation,
      data: { reservationInput, pickerRange }
    } = this.props

    const variables = {
      arriveDate: pickerRange.arriveDate,
      leaveDate: pickerRange.leaveDate,
      guestCount: reservationInput.guestCount,
      accommodations: reservationInput.accommodations.map(acc => acc.id),
      name: values.name,
      email: values.email,
      phone: values.phone,
      liabilityRelease: reservationInput.liabilityRelease,
      stripeToken: values.stripeToken,
      totalPrice: reservationInput.totalPrice
    }

    try {
      await createReservation({ variables })
      this.setState({ reservationComplete: true })
    } catch (error) {
      alert(error.message)
      setErrors('stripeToken', error.message)
      setSubmitting(false)
    }
  }

  render() {
    const { reservationComplete } = this.state
    const {
      data: {
        reservationInput: { name, email, phone, totalPrice }
      }
    } = this.props

    if (reservationComplete)
      return <Redirect to="/app/reservation/confirmation" />

    return (
      <Formik
        initialValues={{
          name,
          email,
          phone,
          nameOnCard: '',
          stripeToken: ''
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required('Please enter your name'),
          email: yup
            .string()
            .email('Please enter a valid email address.')
            .required('Please enter your email address.'),
          phone: yup
            .string()
            .required('Please enter your phone number.')
            .phone('Please enter a valid phone number.'),
          nameOnCard: yup.string().required('Please enter your name'),
          stripeToken: yup.string().required('Fix card input errors')
        })}
        onSubmit={this.onSubmit}
        render={({
          submitForm,
          values,
          errors,
          touched,
          isValid,
          setFieldValue,
          isSubmitting
        }) => (
          <Container title="Payment">
            <Form>
              <ReservationTotal>
                <Title>Reservation Total</Title>
                <Total>${totalPrice}</Total>
              </ReservationTotal>
              <Title>Contact Information</Title>
              <InputGroup>
                <NameInput
                  name="name"
                  placeholder="Full Name"
                  error={errors.name}
                  touched={touched.name}
                />
                <Input
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  error={errors.email}
                  touched={touched.email}
                />
                <Input
                  name="phone"
                  type="tel"
                  placeholder="Phone Number"
                  error={errors.phone}
                  touched={touched.phone}
                />
              </InputGroup>
              <BlueText>
                Please review your reservation details and make your payment
                below.
              </BlueText>
              <InputGroup>
                <NameInput2
                  name="nameOnCard"
                  type="text"
                  placeholder="Name on Card"
                  error={errors.nameOnCard}
                  touched={touched.nameOnCard}
                />
                <StripeCard name={values.nameOnCard} getToken={setFieldValue} />
              </InputGroup>
              <Buttons>
                <Button blue to="/app/reservation/waiver">
                  Back
                </Button>
                <Button
                  component="button"
                  onClick={submitForm}
                  green={isValid && !isSubmitting}
                  disabled={!isValid || isSubmitting}
                >
                  Pay Now
                </Button>
              </Buttons>
            </Form>
          </Container>
        )}
      />
    )
  }
}

const CREATE_RESERVATION = gql`
  mutation createReservation(
    $arriveDate: String
    $leaveDate: String
    $guestCount: Int
    $accommodations: [String]
    $name: String
    $email: String
    $phone: String
    $liabilityRelease: Boolean
    $stripeToken: String
    $totalPrice: String
  ) {
    createReservation(
      input: {
        arriveDate: $arriveDate
        leaveDate: $leaveDate
        guestCount: $guestCount
        accommodations: $accommodations
        name: $name
        email: $email
        phone: $phone
        liabilityRelease: $liabilityRelease
        paymentId: $stripeToken
        totalPrice: $totalPrice
      }
    ) {
      id
    }
  }
`
const GET_RESERVATION_INPUT = gql`
  query {
    reservationInput @client {
      guestCount
      name
      email
      phone
      liabilityRelease
      accommodations {
        id
      }
      includedGuestCount
      additionalGuestFee
      totalPrice
    }
    pickerRange @client {
      arriveDate
      leaveDate
    }
  }
`

export default compose(
  graphql(CREATE_RESERVATION, { name: 'createReservation' }),
  graphql(GET_RESERVATION_INPUT)
)(Payment)

const NameInput = styled(DefaultInput)`
  width: 100%;
`
const Input = styled(DefaultInput)`
  width: 48%;

  @media (max-width: ${props => props.theme.phone}px) {
    width: 100%;
  }
`
const NameInput2 = styled(DefaultInput)`
  width: 100%;
  max-width: 400px;
`
