import React from 'react'
import styled from 'styled-components'
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import DatePicker from 'components/DatePicker'
import { Buttons } from 'components/Container'
import Button from 'components/Button'
import DefaultInput, { ErrorMessage } from 'components/Input'
import { Checkbox } from 'components/DatePicker'

yup.addMethod(yup.string, 'phone', function(message) {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: message || 'Please enter your phone number.',
    test: value => {
      return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-.]?[0-9]{4,6}$/im.test(
        value
      )
    }
  })
})

const Details = ({
  data: { groupReservationInput, pickerRange },
  updateGroupInfo
}) => {
  const areDatesCached = Object.keys(pickerRange).reduce((acc, key) => {
    return acc && pickerRange[key].toString().length > 0
  }, true)

  const areInputsCached = Object.keys(groupReservationInput).reduce(
    (acc, key) => {
      return acc && groupReservationInput[key].toString().length > 0
    },
    true
  )

  return (
    <Formik
      initialValues={groupReservationInput}
      validationSchema={yup.object().shape({
        guestCount: yup
          .number('Please enter a positive number.')
          .positive('Please enter a positive number.')
          .required('Please enter a positive number.'),
        contactName: yup.string().required('Please enter your name'),
        contactEmail: yup
          .string()
          .email('Please enter a valid email address.')
          .required('Please enter your email address.'),
        contactPhone: yup
          .string()
          .required('Please enter your phone number.')
          .phone('Please enter a valid phone number.')
      })}
      onSubmit={values => updateGroupInfo({ variables: values })}
      isInitialValid={areInputsCached}
      render={({
        submitForm,
        values,
        errors,
        touched,
        isValid,
        isSubmitting
      }) => (
        <Form>
          <Container>
            <Text>What are your reservation dates?</Text>
            <DateContainer>
              <DatePicker />
              <CheckboxContainer>
                <Checkbox />
                <Label>
                  Check this box to make a daytime reservation only.
                </Label>
              </CheckboxContainer>
            </DateContainer>
            {!areDatesCached &&
              isSubmitting && (
                <ErrorMessage>
                  Please enter the dates you would like to reserve.
                </ErrorMessage>
              )}
            <Text>How many guests are in your group?</Text>
            <NumGuests
              name="guestCount"
              placeholder="Guest Count"
              type="number"
              error={errors.guestCount}
              touched={touched.guestCount}
            />
            <SmallText>Only include guests who are 5 years or older.</SmallText>
            <Text>Who is your primary contact?</Text>
            <InputContainer>
              <NameInput
                name="contactName"
                placeholder="Full Name"
                error={errors.contactName}
                touched={touched.contactName}
              />
              <Input
                name="contactEmail"
                type="email"
                placeholder="Email Address"
                error={errors.contactEmail}
                touched={touched.contactEmail}
              />
              <Input
                name="contactPhone"
                type="tel"
                placeholder="Phone Number"
                error={errors.contactPhone}
                touched={touched.contactPhone}
              />
            </InputContainer>
          </Container>
          <Buttons>
            <Button to="/" blue>
              Cancel
            </Button>
            <Button
              onClick={() => submitForm()}
              to={
                isValid && areDatesCached
                  ? '/app/group-reservation/group-info'
                  : '#'
              }
              blue={isValid && areDatesCached}
              disabled={!isValid || !areDatesCached}
            >
              Next
            </Button>
          </Buttons>
        </Form>
      )}
    />
  )
}

const UPDATE_GROUP_INFO = gql`
  mutation updateGroupInfo(
    $guestCount: Int
    $contactName: String
    $contactEmail: String
    $contactPhone: String
  ) {
    updateClientGroupReservation(
      guestCount: $guestCount
      contactName: $contactName
      contactEmail: $contactEmail
      contactPhone: $contactPhone
    ) @client
  }
`
const GROUP_RESERVATION_INPUT = gql`
  query {
    groupReservationInput {
      guestCount
      contactName
      contactEmail
      contactPhone
    }
    pickerRange {
      arriveDate
      leaveDate
    }
  }
`

export default compose(
  graphql(GROUP_RESERVATION_INPUT),
  graphql(UPDATE_GROUP_INFO, { name: 'updateGroupInfo' })
)(Details)

const Container = styled.div`
  width: 100%;
  height: 100%;
`
const Text = styled.p`
  margin: 20px 0 0;
  font-size: 20px;
  line-height: 26px;
  color: ${props => props.theme.fontColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 18px;
    line-height: 20px;
  }
`
const SmallText = styled.p`
  margin: 5px 0;
  font-size: 14px;
  line-height: 19px;
  font-style: italic;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.labelFont};
  font-weight: ${props => props.theme.light};
`
const FlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const DateContainer = styled(FlexRow)`
  margin-top: 20px;
  height: 48px;

  @media (max-width: ${props => props.theme.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    margin-top: -10px;
  }
`
const CheckboxContainer = styled(FlexRow)`
  width: 40%;
  margin-left: 20px;

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 100%;
    margin: 0;
  }
`
const Label = styled.label`
  font-size: 14px;
  line-height: 19px;
  font-style: italic;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
`
const InputContainer = styled(FlexRow)`
  flex-wrap: wrap;
  justify-content: space-between;
`
const NumGuests = styled(DefaultInput)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > input {
    margin: 10px 10px 5px 0;
    max-width: 150px;
  }

  /* stylelint-disable */
  & > input::-webkit-inner-spin-button,
  & > input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* stylelint-enable */

  @media (max-width: ${props => props.theme.phone}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const NameInput = styled(DefaultInput)`
  width: 100%;
`
const Input = styled(DefaultInput)`
  width: 48%;

  @media (max-width: ${props => props.theme.phone}px) {
    width: 100%;
  }
`
