import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ResizeObserver from 'resize-observer-polyfill'
import { withRouter } from 'react-router'
import Bar from './Bar'

class ProgressBar extends Component {
  static propTypes = {
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string
      })
    ).isRequired
  }

  state = { width: 300 }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.updateWidth()
      this.observer = new ResizeObserver(() => this.updateWidth())
      this.observer.observe(this.ref)
      window.addEventListener('resize', this.updateWidth)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth)
    this.observer.disconnect()
  }

  updateWidth = () => {
    if (this.ref) {
      const { width } = this.ref.getBoundingClientRect()
      this.setState({ width: width > 0 ? width : 300 })
    }
  }

  render() {
    const { width } = this.state
    const { steps, location } = this.props
    const stepTitles = steps.map(step => step.title)
    const currentStep =
      steps.findIndex(step => step.path === location.pathname) + 1
    return (
      <Container innerRef={x => (this.ref = x)}>
        <Bar
          containerWidth={width}
          steps={stepTitles}
          currentStep={currentStep}
        />
      </Container>
    )
  }
}

export default withRouter(ProgressBar)

const Container = styled.div`
  width: 100%;
`
