import styled from 'styled-components'

export const Details = styled.div`
  max-width: 500px;
  margin: 0 auto;
`
export const Subtitle = styled.div`
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: ${props => props.theme.regular};
  color: #aab4cb;
  margin-top: 24px;
  padding-bottom: 6.5px;
  border-bottom: solid 1px #d5dceb;
`
export const Accomodations = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22.5px;
  margin-bottom: 8px;
  width: 100%;
`
export const LineItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
  &:last-of-type {
    margin-bottom: 0;
  }
`
export const Name = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 18px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  font-style: italic;
`
export const Price = styled.div`
  color: ${props => props.theme.mainColor};
  font-size: 16px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  font-style: italic;
  display: flex;
  align-items: center;
`
export const Disclaimer = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 14px;
  font-weight: ${props => props.theme.regular};
  color: #aab4cb;
  margin-top: 13.5px;
  font-style: italic;

  & > em {
    color: ${props => props.theme.mainColor};
  }
`
export const Guests = styled.div`
  margin-top: 15px;
  font-family: ${props => props.theme.secondaryFont};
  font-size: 20px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  font-style: italic;
  display: flex;
  justify-content: space-between;
`
export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  align-items: flex-end;
  font-family: ${props => props.theme.secondaryFont};
  font-size: 20px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  margin-top: 30px;
  width: 100%;
  padding-top: 15px;
  border-top: solid 1px #d5dceb;
`
export const TotalPrice = styled.div`
  color: ${props => props.theme.mainColor};
  font-size: 26px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
`
export const GuestCount = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 18px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  font-style: italic;
`
export const Dates = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 20px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  margin-top: 15.5px;
  margin-bottom: 22px;
  font-style: italic;
`
