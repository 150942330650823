import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'

const ProgressIndicator = ({ steps, location }) => {
  const currentStep = steps.findIndex(step => step.path === location.pathname)
  return (
    <Container>
      {steps.map((step, i) => (
        <Step key={i} active={currentStep === i}>
          {step.title}
        </Step>
      ))}
    </Container>
  )
}

export default withRouter(ProgressIndicator)

const Container = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0 10px 0;
  border-bottom: 1px solid #d5dceb;
`
const Step = styled.li`
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => (props.active ? 600 : 300)};
  color: ${props =>
    props.active ? 'rgb(39, 148, 242)' : 'rgb(170, 180, 203)'};
  margin: 0 10px;
`
