import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Checkmark } from 'icons/check.svg'

export default ({ checked, onClick }) => (
  <Box checked={checked} onClick={onClick}>
    <Check />
  </Box>
)

const Box = styled.div`
  background: ${props => (props.checked ? props.theme.mainColor : 'white')};
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  transition-duration: 0.3s;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${props => props.theme.phone}px) {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
`
const Check = styled(Checkmark)`
  width: 15px;
  height: 15px;

  @media (max-width: ${props => props.theme.phone}px) {
    width: 20px;
    height: 20px;
  }
`
