import React from 'react'
import styled from 'styled-components'
import { Layout, Aside } from 'components/AppLayout'
import Details from './ReservationDetails'
import AccommodationsList from './AccommodationsList'
import CampMap from './CampgroundMap'

export default () => (
  <AppLayout>
    <Aside title="Reservation Details">
      <Details />
    </Aside>
    <AccommodationsSection>
      <Title>
        Choose Your Accommodations
        <CampMap />
      </Title>
      <AccommodationsList />
    </AccommodationsSection>
  </AppLayout>
)
const AppLayout = styled(Layout)`
  background: #f4f4fa;
`
const AccommodationsSection = styled.div`
  background: #f4f4fa;
  padding: 20px;
  margin: 0 auto;
  max-width: 1000px;

  @media (max-width: ${props => props.theme.phone}px) {
    padding: 20px 0;
  }
`
const Title = styled.h1`
  padding-bottom: 15px;
  margin: 15px;
  line-height: 34px;
  font-size: 34px;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.mainFont};
  font-weight: ${props => props.theme.black};
  border-bottom: 1px solid ${props => props.theme.mainColor};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${props => props.theme.desktop}px) {
    font-size: 28px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    text-align: center;
    border-bottom: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    padding: 0;
  }
`
