import React from 'react'
import styled from 'styled-components'
import ImageCarousel from './ImageCarousel'
import AddRemoveButton from './AddRemoveButton'

import { ReactComponent as X } from 'icons/x-icon.svg'

export default ({
  id,
  isReserved,
  name,
  price,
  description,
  includedGuestCount,
  images,
  closeModal,
  isOpen
}) =>
  isOpen ? (
    <Background>
      <Content>
        <ImgDiv>
          <ImageCarousel images={images} />
          <ReserveButton id={id} isReserved={isReserved} />
          <Close onClick={closeModal} />
        </ImgDiv>
        <Details>
          <PriceContainer>
            <Dollar>$</Dollar>
            <Price>{price}</Price>
            <PerNight>/night</PerNight>
          </PriceContainer>
          <Name>{name}</Name>
          <Text>{description}</Text>
          {includedGuestCount > 0 && (
            <BlueText>
              Sleeps {includedGuestCount} people - Each additional person after{' '}
              {includedGuestCount} will be $3.00 per person.
            </BlueText>
          )}
        </Details>
      </Content>
    </Background>
  ) : null

const Background = styled.div`
  overflow: scroll;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  animation: openModal 0.5s;

  @keyframes openModal {
    from {
      opacity: 0;
      transform: scale(1.025);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`
const Close = styled(X)`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 20px;
  cursor: pointer;

  path {
    stroke: white;
    stroke-width: 2px;
  }
`
const Content = styled.div`
  position: relative;
  margin: 120px auto;
  width: 80%;
  max-width: 800px;
  max-height: calc(100% - 120px);
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.45);

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 90%;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    width: 100%;
  }
`
const ImgDiv = styled.div`
  position: relative;
`
const Details = styled.div`
  padding: 40px 60px;
  width: 100%;
  background-color: white;
  position: relative;

  @media (max-width: ${props => props.theme.tablet}px) {
    padding: 20px;
    margin-bottom: 20px;
  }
`
const Name = styled.h1`
  width: 100%;
  padding: 0 0 15px;
  border-bottom: 1px solid #d5dceb;
  font-size: 20px;
  line-height: 26px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.semiBold};
  color: ${props => props.theme.fontColor};
  margin: 0 0 20px;
`
const Text = styled.p`
  font-size: 14pt;
  line-height: 21pt;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.light};
  color: ${props => props.theme.fontColor};

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 12pt;
    line-height: 18pt;
  }
`
const BlueText = styled.p`
  margin: 20px 0 0;
  font-size: 14pt;
  line-height: 21pt;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.regular};
  font-style: italic;
  color: ${props => props.theme.mainColor};

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 12pt;
    line-height: 18pt;
  }
`
const PriceContainer = styled.div`
  position: absolute;
  right: 60px;
  top: 40px;
  display: flex;

  @media (max-width: ${props => props.theme.tablet}px) {
    right: 20px;
    top: 20px;
  }
`
const Dollar = styled.div`
  margin-top: 5px;
  font-size: 17px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  font-style: italic;
  color: ${props => props.theme.mainColor};
`
const Price = styled.div`
  font-size: 35px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  font-style: italic;
  color: ${props => props.theme.mainColor};
`
const PerNight = styled.div`
  margin-top: 5px;
  font-size: 14px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  font-style: italic;
  color: ${props => props.theme.mainColor};
  padding-left: 3px;
`
const ReserveButton = styled(AddRemoveButton)`
  position: absolute;
  bottom: 20px;
  right: 20px;

  @media (max-width: ${props => props.theme.tablet}px) {
    top: 20px;
    right: auto;
    left: 20px;
    padding: 24px 20px;
    bottom: auto;
  }
`
