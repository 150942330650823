import styled from 'styled-components'

export const ReservationTotal = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: 20px;
  align-items: center;
  width: 100%;
`
export const Title = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  color: ${props => props.theme.fontColor};
  font-weight: ${props => props.theme.bold};
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
`
export const Total = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  color: ${props => props.theme.mainColor};
  font-weight: ${props => props.theme.bold};
  font-size: 34px;
`
export const InputGroup = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const BlueText = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  color: ${props => props.theme.mainColor};
  font-size: 16px;
  font-style: italic;
  margin-top: 12px;
  margin-bottom: 20px;
  line-height: 24px;
`
