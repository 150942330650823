import React from 'react'
import styled from 'styled-components'

import { ReactComponent as X } from 'icons/x-icon.svg'

export default ({ toggleModal }) => (
  <Container>
    <Close onClick={toggleModal} />
    <Title>Camp Terra Rose</Title>
    <Subtitle>
      MINOR and ADULT RELEASE AND WAIVER OF LIABILITY AND INDEMNITY AGREEMENT
    </Subtitle>
    <ScrollContainer>
      <Paragraph>
        IN CONSIDERATION of allowing the below MINOR participant to camp, hike,
        compete, observe, work for, or participate (“participate”) in any way in
        the activity of camping, operating, riding as a passenger, or observing
        within the property owned by Camp Terra Rose and/or Terra Rose Valley
        Ranch LLC whether leased and or owned by Camp Terra Rose or Matthew and
        Susan Isabell or family members and/or being permitted to enter for any
        purpose any RESTRICTED AREA (defined as any area requiring special
        authorization, credentials or permission to enter or any area to which
        admission by the general public is restricted or prohibited, including
        but not limited to the campground area.
      </Paragraph>
      <Paragraph>
        <Bold>EACH OF THE UNDERSIGNED, </Bold> for himself/ herself, his/her
        personal representatives, heirs, and next of kin agrees that:
      </Paragraph>
      <Paragraph>
        1.<Bold>
          {' '}
          THE MINOR AND PARENT OR GUARDIAN HEREBY ASSUME FULL RESPONSIBILITY FOR
          THE RISK OF BODILY INJURY, DEATH OR PROPERTY DAMAGE{' '}
        </Bold>
        due to negligence of Releasees (as identified below) or otherwise, while
        in or upon the CAMPGROUND PROPERTY whether owned and or leased for any
        purpose and/or while participating in any way in the Event. The
        undersigned recognize and understand that there are risks and dangers
        associated with participation in the Event and admission within the
        RESTRICTED and or UNRESTRICTED AREA that could cause severe bodily
        injury, disability, and death. Further, the risks and dangers may be
        caused by the negligent failure to act of the Releasees and others. All
        of the risks and dangers associated with participating in the Event
        and/or entry into the AREAs are assumed notwithstanding.
      </Paragraph>
      <Paragraph>
        2.<Bold> THE MINOR AND PARENT OR GUARDIAN </Bold>
        release, waive, discharge, and covenant not to sue the promoters,
        participants, any affiliated associations, sanctioning or administrative
        organizations or any affiliated entities thereof, and all persons in any
        RESTRICTED AREA, sponsors, advertisers, owners, lessors and lessees of
        premises used to conduct the Event, premises and event inspectors,
        surveyors, underwriters, brokers, consultants and others who give
        recommendations, directions, or instructions, or engage in risk
        evaluation or loss control activities regarding the premises or EVENT(S)
        and for each of them, their directors, officers, agents, and employees
        (all for the purposes herein referred to as (“Releasees”), from all
        liability to ourselves, the undersigned, our personal representatives,
        assigns, executors, heirs, and next of kin, for any and all claims,
        demands, losses or damages of the MINOR and/or parent or guardian on
        account of any injury, including, but not limited to the death or injury
        of the parent/guardian or MINOR or damage to property, all of which is
        caused or alleged to be caused by the negligence of the Releasees or
        otherwise.
      </Paragraph>
      <Paragraph>
        3.<Bold> THE PARENT AND/OR GUARDIAN </Bold>
        hereby agrees to indemnify and save and hold harmless, the Releasees and
        each of them from any loss, liability, damage, or cost they may occur
        due, in any manner or degree, to the presence of the parent/ guardian or
        the MINOR in the RESTRICTED AREA, or related in any way to their
        participation in or presence at the Event and whether caused by
        negligence of the Releasees or otherwise. The parent and/or guardian
        further recognize and agree they are executing this Waiver and Release
        of Liability and Indemnity Agreement on behalf of themselves and on
        behalf of the MINOR.
      </Paragraph>
      <Paragraph>
        4.{' '}
        <Bold>
          This release and Waiver of Liability, Assumption of Risk and Indemnity
          Agreement{' '}
        </Bold>
        extends to all acts of negligence by the Releasees,{' '}
        <Bold> INCLUDING NEGLIGENT RESCUE OPERATIONS </Bold> and is intended to
        be as broad and inclusive as is permitted by the laws of the Province or
        State in which the Event(s) is/are conducted and that if any portion
        thereof is held invalid, it is agreed that the balance shall,
        notwithstanding, continue in full legal force and effect.
      </Paragraph>
      <Paragraph>
        <Bold>
          THE PARENT OR GUARDIAN HAS READ AND VOLUNTARILY SIGNS THE WAIVER AND
          RELEASE OF LIABILITY AND INDEMNITY AGREEMENT AND DOES SO VOLUNTARILY
          AND WITH THE UNDERSTANDING THAT SUBSTANTIAL RIGHTS ARE BEING GIVEN UP.
          I/ WE FURTHER ACKNOWLEDGE THAT FAILURE TO WITNESS OR NOTARIZE THIS
          AGREEMENT SHALL NOT AFFECT ITS VALIDITY.
        </Bold>
      </Paragraph>
    </ScrollContainer>
  </Container>
)
const Close = styled(X)`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 20px;
  cursor: pointer;
`
const Container = styled.div`
  position: relative;
  margin: 60px auto;
  padding: 60px 80px 50px;
  height: 100%;
  max-height: calc(100% - 120px);
  max-width: 760px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.45);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: fadein 0.5s;

  @keyframes fadein {
    from {
      opacity: 0;
      transform: scale(1.025);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media (max-width: ${props => props.theme.desktop}px) {
    margin: 50px auto 40px;
    padding: 50px 30px 30px;
    width: 100%;
    max-height: calc(100% - 80px);
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    margin: 20px auto;
    width: calc(100% - 80px);
    max-height: calc(100% - 40px);
    max-width: none;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    width: calc(100% - 40px);
  }
`
const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 10px 20px 0 0;
  -webkit-scrolling: auto;
  -webkit-overflow-scrolling: touch;
`
const Title = styled.h1`
  font: ${props => props.theme.waiverTitle};
  color: ${props => props.theme.fontColor};
  margin: 0 0 20px;
`
const Subtitle = styled.h2`
  font: ${props => props.theme.waiverSubtitle};
  color: ${props => props.theme.fontColor};
  margin: 0 0 20px;
`
const Paragraph = styled.p`
  font: ${props => props.theme.waiverText};
  color: ${props => props.theme.fontColor};
  margin: 0 0 20px;
`
const Bold = styled.span`
  font-weight: 600;
`
