import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import { withApollo } from 'react-apollo'
import { Buttons } from 'components/Container'
import Button from 'components/Button'

class Complete extends Component {
  componentWillUnmount = () => {
    this.props.client.resetStore()
  }

  render() {
    return (
      <Fragment>
        <ThankYou>Thank You!</ThankYou>
        <BlueText>Your reservation request has been submitted.</BlueText>
        <GrayText>
          A staff member is reviewing your reservation request. Once your
          request has been reviewed, we will be contacting you sortly with
          pricing and payment information. If you have questions or would like
          to modify your reservation please call Susan Isabell at 479-640-4467.
        </GrayText>
        <Buttons>
          <Button to="/" blue>
            Done
          </Button>
        </Buttons>
      </Fragment>
    )
  }
}

export default withApollo(Complete)

const ThankYou = styled.h2`
  margin: 20px 0 0;
  font-size: 26px;
  line-height: 36px;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 18px;
    line-height: 20px;
  }
`
const BlueText = styled.p`
  margin: 20px 0 0;
  font-size: 20px;
  line-height: 26px;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 18px;
    line-height: 20px;
  }
`
const GrayText = styled.p`
  margin: 20px 0;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.fontColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.light};
`
