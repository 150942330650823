import React from 'react'
import { Switch, Route } from 'react-router'

import Payment from './ReservationPayment'
import Find from './FindReservation'

export default () => (
  <Switch>
    <Route path="/app/group-reservation/manage/pay" component={Payment} />
    <Route path="/app/group-reservation/manage" component={Find} />
  </Switch>
)
