import styled from 'styled-components'

export default styled.div`
  position: fixed;
  height: 80px;
  width: 420px;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 50;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 340px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    width: 100%;
    box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.15);
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    height: 60px;
  }
`
