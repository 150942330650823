import React, { Fragment } from 'react'
import { GatewayProvider, GatewayDest, Gateway } from 'react-gateway'
import styled from 'styled-components'
import Context from 'components/Layout'
import Navbar from 'components/Navbar'
import AppAside from './Aside'
import AppNav from './Navigation'
import Body from './Body'

export const Layout = ({ className, noaside, nonav, fluid, children }) => (
  <Context>
    <GatewayProvider>
      <Container noaside={noaside} nonav={nonav}>
        <Navbar />
        <Body className={className} nonav={nonav} fluid={fluid}>
          {children}
        </Body>
        <GatewayDest name="app-aside" component={EmptyGateway} />
        <GatewayDest name="app-nav" component={EmptyGateway} />
      </Container>
    </GatewayProvider>
  </Context>
)

export const Aside = ({ children, title }) => (
  <Gateway into="app-aside">
    <AppAside title={title}>{children}</AppAside>
  </Gateway>
)

export const Navigation = ({ children }) => (
  <Gateway into="app-nav">
    <AppNav>{children}</AppNav>
  </Gateway>
)

const EmptyGateway = ({ children }) => <Fragment>{children}</Fragment>

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 420px;
  grid-template-rows: 70px 1fr;
  grid-template-areas:
    'navbar navbar'
    ${props => (props.noaside ? "'content content'" : "'content aside'")};

  @media (max-width: ${props => props.theme.desktop}px) {
    grid-template-columns: 1fr 340px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 50px 1fr;
    grid-template-areas: 'navbar' ${props =>
        props.noaside ? "'content' 'content'" : "'details' 'content'"};
  }
`
