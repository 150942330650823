import React from 'react'
import styled from 'styled-components'
import { Buttons } from 'components/Container'
import Button from 'components/Button'
import DefaultInput from 'components/Input'
import Checkbox from 'components/Checkbox'
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import { Formik, Form, FieldArray } from 'formik'
import * as yup from 'yup'

const activities = [
  'Skeet Shooting',
  'Rifle Shooting',
  'Rapelling',
  'Team Building',
  'Volleyball',
  'Fishing',
  'Archery',
  'Hiking',
  'Canoeing'
]

const GroupInfo = ({ data: { groupReservationInput }, updateGroupInfo }) => {
  const areInputsCached = Object.keys(groupReservationInput).reduce(
    (acc, key) => {
      return acc && groupReservationInput[key].toString().length > 0
    },
    true
  )

  return (
    <Formik
      initialValues={groupReservationInput}
      validationSchema={yup.object().shape({
        groupName: yup.string().required('Please enter a positive number.'),
        reservationPurpose: yup.string().required('Please enter your name')
      })}
      onSubmit={values => updateGroupInfo({ variables: values })}
      isInitialValid={areInputsCached}
      render={({
        submitForm,
        values,
        errors,
        touched,
        isValid,
        isSubmitting
      }) => (
        <Form>
          <Container>
            <Text>What is your group name?</Text>
            <Input
              name="groupName"
              placeholder="Group Name"
              error={errors.groupName}
              touched={touched.groupName}
            />
            {console.log(values.activities)}
            <Text>What is your reservation for?</Text>
            <Textbox
              name="reservationPurpose"
              placeholder="Response"
              component="textarea"
              error={errors.reservationPurpose}
              touched={touched.reservationPurpose}
            />
            <Text>What activities will your group be doing?</Text>
            <FieldArray
              name="activities"
              render={({ push, remove }) => (
                <Activities>
                  {activities.map((activity, i) => {
                    let valuesIndex = values.activities.indexOf(activity)
                    let isChecked = values.activities.includes(activity)
                    return (
                      <Activity key={i}>
                        <Checkbox
                          checked={isChecked}
                          onClick={() => {
                            if (isChecked) remove(valuesIndex)
                            else push(activity)
                          }}
                        />
                        <Label>{activity}</Label>
                      </Activity>
                    )
                  })}
                </Activities>
              )}
            />
          </Container>
          <Buttons>
            <Button to="/app/group-reservation" blue>
              Back
            </Button>
            <Button
              onClick={() => submitForm()}
              to={isValid ? '/app/group-reservation/submit' : '#'}
              blue={isValid}
              disabled={!isValid}
            >
              Next
            </Button>
          </Buttons>
        </Form>
      )}
    />
  )
}

const UPDATE_GROUP_INFO = gql`
  mutation updateGroupInfo(
    $groupName: String
    $reservationPurpose: String
    $activities: [String]
  ) {
    updateClientGroupReservation(
      groupName: $groupName
      reservationPurpose: $reservationPurpose
      activities: $activities
    ) @client
  }
`
const GROUP_RESERVATION_INPUT = gql`
  query {
    groupReservationInput {
      groupName
      reservationPurpose
      activities
    }
  }
`

export default compose(
  graphql(UPDATE_GROUP_INFO, { name: 'updateGroupInfo' }),
  graphql(GROUP_RESERVATION_INPUT)
)(GroupInfo)

const Container = styled.div`
  width: 100%;
  height: 100%;
`
const Text = styled.p`
  margin: 20px 0 0;
  font-size: 20px;
  line-height: 26px;
  color: ${props => props.theme.fontColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 18px;
    line-height: 20px;
  }
`
const Activities = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`
const Activity = styled.div`
  width: calc(25% - 20px);
  margin: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${props => props.theme.tablet}px) {
    width: calc(33% - 20px);
  }

  @media (max-width: ${props => props.theme.phone}px) {
    width: 100%;
  }
`
const Label = styled.label`
  font-size: 12px;
  line-height: 12px;
  font-style: italic;
  color: ${props => props.theme.fontColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.light};

  @media (max-width: ${props => props.theme.phone}px) {
    font-size: 14pt;
    line-height: 14pt;
  }
`
const Input = styled(DefaultInput)`
  width: 100%;
`
const Textbox = styled(DefaultInput)`
  & > textarea {
    width: 100%;
    height: 80px;
    resize: none;
    border: none;
    box-sizing: border-box;
    line-height: 20px;
  }
`
