import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query MapQuery2 {
        imageSharp(original: { src: { regex: "/map-image/" } }) {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={data => children(data.imageSharp)}
  />
)
