import React from 'react'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Formik } from 'formik'
import * as yup from 'yup'
import ProgressBar from './ProgressBar'
import AccommodationLineItem from './AccommodationLineItem'
import DatePicker from 'components/DatePicker'
import { Navigation } from 'components/AppLayout'
import Button from 'components/Button'

import {
  Details,
  Section,
  Accomodations,
  LineItem,
  Name,
  Price,
  Disclaimer,
  Total,
  TotalPrice,
  FinalPrice,
  GuestInput,
  Ages
} from './styles'

const steps = [
  { title: 'Accommodations', path: '/app/accommodations' },
  { title: 'Waiver', path: '/app/reservation/waiver' },
  { title: 'Payment', path: '/app/reservation/checkout' }
]

const Accommodations = ({
  data: { reservationInput, pickerRange },
  loading,
  updateGuestCount
}) => {
  if (loading) return null

  const areDatesCached = Object.keys(pickerRange).reduce((acc, key) => {
    return acc && pickerRange[key].toString().length > 0
  }, true)

  const areAccommodationsCached = reservationInput.accommodations.length > 0

  const areInputsCached = Object.keys(reservationInput).reduce((acc, key) => {
    return acc && reservationInput[key].toString().length > 0
  }, true)

  return (
    <Formik
      initialValues={reservationInput}
      validationSchema={yup.object().shape({
        guestCount: yup
          .number('(0 - 50)')
          .positive('(0 - 50)')
          .max(
            50,
            'For groups larger than 50 please request a large group reservation.'
          )
          .required('Please enter a positive number.')
      })}
      onSubmit={values => updateGuestCount({ variables: values })}
      isInitialValid={areInputsCached}
      render={({
        submitForm,
        values,
        errors,
        touched,
        isValid,
        isSubmitting,
        handleChange
      }) => (
        <Details>
          <ProgressBar steps={steps} />
          <DatePicker />
          <Section>
            {' '}
            Accommodations ({reservationInput.accommodations.length})
          </Section>
          <Accomodations count={reservationInput.accommodations.length}>
            {reservationInput.accommodations
              .map((accommodation, i) => (
                <AccommodationLineItem
                  key={i}
                  id={accommodation.id}
                  name={accommodation.name}
                  price={accommodation.price}
                />
              ))
              .reverse()}
          </Accomodations>
          <Section>Guest count</Section>
          <Disclaimer>
            Includes <b> {reservationInput.includedGuestCount + ' '}</b>
            guests. Each additional is <em> $3/person</em>.
          </Disclaimer>
          <GuestInput
            name="guestCount"
            placeholder="Guest Count"
            type="number"
            onChange={e => {
              handleChange(e)
              updateGuestCount({ variables: { guestCount: e.target.value } })
            }}
            error={errors.guestCount}
            touched={touched.guestCount}
          />
          <Ages>Only include guests who are 5 years or older.</Ages>
          <LineItem>
            <Name>Additional Guest Charge </Name>
            <Price>${reservationInput.additionalGuestFee}</Price>
          </LineItem>
          <Total>
            Total
            <TotalPrice>
              <FinalPrice>${reservationInput.totalPrice}</FinalPrice>
            </TotalPrice>
          </Total>
          <Navigation>
            <Button
              onClick={() => submitForm()}
              to={
                isValid && areDatesCached && areAccommodationsCached
                  ? '/app/reservation/waiver'
                  : '#'
              }
              blue={isValid && areDatesCached && areAccommodationsCached}
              disabled={!isValid || !areDatesCached || !areAccommodationsCached}
            >
              Next
            </Button>
          </Navigation>
        </Details>
      )}
    />
  )
}

const UPDATE_GUEST_COUNT = gql`
  mutation UpdateGuestCount($guestCount: Int) {
    updateReservationInput(guestCount: $guestCount) @client {
      guestCount
    }
  }
`
const GET_RESERVATION_INPUT = gql`
  query {
    reservationInput @client {
      guestCount
      accommodations {
        id
        name
        price
      }
      includedGuestCount
      additionalGuestFee
      totalPrice
    }
    pickerRange @client {
      arriveDate
      leaveDate
    }
  }
`

export default compose(
  graphql(UPDATE_GUEST_COUNT, { name: 'updateGuestCount' }),
  graphql(GET_RESERVATION_INPUT)
)(Accommodations)
