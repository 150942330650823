import styled from 'styled-components'
import DefaultInput from 'components/Input'
import { Form } from 'formik'

export const Details = styled(Form)`
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`
export const Section = styled.div`
  width: 100%;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: ${props => props.theme.regular};
  color: #aab4cb;
  margin: 20px 0 10px;
  border-bottom: solid 1px #d5dceb;
  padding-bottom: 5px;
`
export const Accomodations = styled.div`
  margin: 0;
  width: 100%;
  max-height: 105px;
  overflow-y: scroll;
  box-shadow: ${props =>
    props.count > 3 ? 'inset 0 -15px 30px -20px rgba(0,0,0,0.3)' : 'none'};
  padding-bottom: 10px;

  @media (max-width: ${props => props.theme.phone}px) {
    max-height: 100px;
  }
`
export const LineItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
  &:last-of-type {
    margin-bottom: 0;
  }
`
export const Name = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 18px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  font-style: italic;
`
export const Price = styled.div`
  color: ${props => props.theme.mainColor};
  font-size: 16px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  font-style: italic;
  display: flex;
  align-items: center;
`
export const Disclaimer = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 14px;
  font-weight: ${props => props.theme.regular};
  color: #aab4cb;
  margin: 10px 0;
  font-style: italic;

  & > em {
    color: ${props => props.theme.mainColor};
  }

  & > b {
    color: ${props => props.theme.mainColor};
    font-weight: ${props => props.theme.bold};
    font-size: 18px;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    margin: 10px 0 0;
  }
`
export const GuestInput = styled(DefaultInput)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > div {
    height: auto;
  }

  & > input {
    margin: 10px 10px 5px 0;
    max-width: 150px;
    border: solid 1px #d5dceb;
    border-radius: 4px;
  }

  /* stylelint-disable */
  & > input::-webkit-inner-spin-button,
  & > input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* stylelint-enable */

  @media (max-width: ${props => props.theme.phone}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const Ages = styled.div`
  margin: 0 0 15px;
  font-size: 14px;
  line-height: 19px;
  font-style: italic;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.labelFont};
  font-weight: ${props => props.theme.light};
`
export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  align-items: flex-end;
  font-family: ${props => props.theme.secondaryFont};
  font-size: 20px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  margin: 30px 0;
  width: 100%;
  padding-top: 15px;
  border-top: solid 1px #d5dceb;

  @media (max-width: ${props => props.theme.phone}px) {
    margin-top: 10px;
  }
`
export const TotalPrice = styled.div`
  color: ${props => props.theme.mainColor};
  font-size: 26px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
`
export const FinalPrice = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 22px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.mainColor};
  width: 100%;
`
