import React from 'react'
import styled from 'styled-components'
import { Layout, Aside } from 'components/AppLayout'
import Container, { Buttons } from 'components/Container'
import Button from 'components/Button'
import Details from './ReservationDetails'
import { ReactComponent as Card } from 'icons/card-icon.svg'

const details = {
  arriveDate: 'May 21, 2018',
  departureDate: 'May 26, 2018',
  guests: {
    total: 150
  },
  groupName: 'Southside Summer Camp',
  contact: {
    name: 'John Hatfield',
    email: 'john@gmail.com',
    phone: '123-654-1234'
  },
  activities: [
    { activity: 'Canoeing' },
    { activity: 'Rifle Shooting' },
    { activity: 'Archery' },
    { activity: 'Team Building' },
    { activity: 'Rifle Shooting' },
    { activity: 'Archery' },
    { activity: 'Team Building' },
    { activity: 'Rifle Shooting' },
    { activity: 'Archery' },
    { activity: 'Team Building' },
    { activity: 'Rifle Shooting' },
    { activity: 'Archery' },
    { activity: 'Team Building' },
    { activity: 'Rifle Shooting' },
    { activity: 'Archery' },
    { activity: 'Team Building' }
  ],
  totalPrice: 950
}

export default () => (
  <Layout>
    <Aside title="Reservation Details">
      <Details {...details} />
    </Aside>
    <Container title="Group Reservation Payment">
      <TotalContainer>
        <Total>Reservation Total</Total>
        <Price>${details.totalPrice}</Price>
      </TotalContainer>
      <Text>Enter your payment information below to complete payment.</Text>
      <Input type="text" placeholder="Name on Card" />
      <HiddenInputContainer>
        <CardIcon />
        <CardNumber type="number" placeholder="Card Number" />
        <Expiration type="text" placeholder="mm/dd" />
        <CCV type="number" placeholder="CCV" />
      </HiddenInputContainer>
      <Buttons>
        <Button to="group-payment" green>
          Pay Now
        </Button>
      </Buttons>
    </Container>
  </Layout>
)
const TotalContainer = styled.div`
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Total = styled.h2`
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  color: ${props => props.theme.fontColor};
  font-family: ${props => props.theme.mainFont};
  font-weight: ${props => props.theme.bold};
  text-align: center;
`
const Price = styled.h2`
  margin: 0 0 0 10px;
  font-size: 35px;
  line-height: 45px;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.mainFont};
  font-weight: ${props => props.theme.bold};
  text-align: center;
`
const Text = styled.p`
  margin: 20px 0;
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.regular};
  text-align: center;
`
const Input = styled.input`
  padding: 10px;
  margin: 10px auto;
  width: 100%;
  background: white;
  border: 1px solid #d5dceb;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  color: ${props => props.theme.fontColor};
  text-align: center;
  max-width: 500px;

  &::placeholder {
    font-size: 14px;
    font-style: italic;
    font-family: ${props => props.theme.secondaryFont};
    font-weight: ${props => props.theme.light};
    color: #aab4cb;
  }

  &:focus {
    &::placeholder {
      color: transparent;
      transition-duration: 0.5s;
    }

    outline: none;
    caret-color: ${props => props.theme.mainColor};
  }
`
const HiddenInput = styled(Input)`
  margin: 0;
  background-color: transparent;
  border: none;
  border-radius: none;
  text-align: left;
  box-shadow: none;
`
const CardNumber = styled(HiddenInput)`
  width: 50%;
`
const Expiration = styled(HiddenInput)`
  width: 15%;
`
const CCV = styled(HiddenInput)`
  width: 15%;
`
const CardIcon = styled(Card)`
  width: 8%;
  height: 40px;
  margin-left: 10px;
`
const HiddenInputContainer = styled.div`
  padding: 0;
  margin: 10px auto;
  width: 100%;
  background: white;
  border: 1px solid #d5dceb;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
