import React from 'react'
import { Switch, Route } from 'react-router'
import GroupReservation from './group-reservation'
import Reservation from './reservation'
import Accommodations from './accommodations'

export default () => (
  <Switch>
    <Route path="/app/reservation" component={Reservation} />
    <Route path="/app/group-reservation" component={GroupReservation} />
    <Route path="/app/accommodations" component={Accommodations} />
  </Switch>
)
