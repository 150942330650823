import styled from 'styled-components'
import DefaultInput from 'components/Input'

export const Paragraph = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 16px;
  color: ${props => props.theme.fontColor};
  margin: 30px 0;
  line-height: 24px;

  & > em {
    font-style: italic;
    color: ${props => props.theme.mainColor};
    cursor: pointer;
  }
`
export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  z-index: 3000;
  animation: fadein 0.5s;

  @keyframes fadein {
    from {
      opacity: 0;
      transform: scale(1.025);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`
export const Text = styled.div`
  margin: 0;

  & > em {
    font-style: italic;
    color: ${props => props.theme.mainColor};
    cursor: pointer;
  }
`
export const AgreeTerms = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.secondaryFont};
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.fontColor};
  margin: 30px 0;
`
export const BlueText = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 16px;
  color: ${props => props.theme.mainColor};
  font-style: italic;
  margin-bottom: 32px;
  line-height: 24px;
`
export const SignatureTitle = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 20px;
  color: ${props => props.theme.fontColor};
  font-weight: ${props => props.theme.bold};
  margin-bottom: 10px;
`
export const Input = styled(DefaultInput)`
  width: 100%;

  & > input {
    font-family: 'Dancing Script', sans-serif;
    font-size: 24px;

    &::placeholder {
      font-size: 16px;
      font-family: Roboto, sans-serif;
    }
  }
`
