import React from 'react'
import styled from 'styled-components'
import { Layout } from 'components/AppLayout'
import Container, { Buttons } from 'components/Container'
import Button from 'components/Button'

export default () => (
  <Layout noaside nonav>
    <Container title="Retrieve Your Reservation">
      <Text>
        Please submit your large group reservation request by clicking “Submit
        Request” below. After submitting, a member of our staff will contact you
        with pricing and payment information.
      </Text>
      <Input type="text" placeholder="Confirmation Number" />
      <Buttons>
        <Button to="group-payment" blue>
          Find Reservation
        </Button>
      </Buttons>
    </Container>
  </Layout>
)
const Text = styled.p`
  margin: 20px 0;
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.regular};
  text-align: center;
`
const Input = styled.input`
  padding: 10px;
  margin: 10px auto;
  width: 100%;
  background: white;
  border: 1px solid #d5dceb;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  color: ${props => props.theme.fontColor};
  text-align: center;
  max-width: 400px;

  &::placeholder {
    font-size: 14px;
    font-style: italic;
    font-family: ${props => props.theme.secondaryFont};
    font-weight: ${props => props.theme.light};
    color: #aab4cb;
  }

  &:focus {
    &::placeholder {
      color: transparent;
      transition-duration: 0.5s;
    }

    outline: none;
    caret-color: ${props => props.theme.mainColor};
  }
`
