import React from 'react'
import styled from 'styled-components'

import Container from 'components/Container'
import Button from 'components/Button'

export default () => (
  <Container title="Cancel Your Reservation">
    <BlueText>
      By clicking “Cancel Reservation” you are agreeing to forfeit your
      reservation. Your payment will be refunded in full within 1 week of
      cancellation. If you wish to make changes to your reservation please call
      us at 479-640-4467. If you do not wish to cancel your reservation click
      "Nevermind, don't Cancel" to return to our home page.
    </BlueText>
    <Links>
      <Button red>Cancel Reservation</Button>
      <Button blue to="/">
        Nevermind, don't Cancel
      </Button>
    </Links>
  </Container>
)

const BlueText = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  font-size: 18px;
  color: ${props => props.theme.mainColor};
  font-style: italic;
  text-align: center;
  margin-top: 22px;
  margin-bottom: 27px;
  padding-bottom: 27px;
  line-height: 21px;
  border-bottom: 1px solid #d5dceb;
`
const Links = styled.div`
  display: flex;
  justify-content: space-around;
`
