import React, { Component } from 'react'
import styled from 'styled-components'
import Button from 'components/Button'
import Container, { Buttons } from 'components/Container'
import { withApollo } from 'react-apollo'

class Complete extends Component {
  componentWillUnmount = () => {
    this.props.client.resetStore()
  }

  render() {
    return (
      <Container title="Thank You!">
        <BlueText>Your reservation has been scheduled.</BlueText>
        {/* <ConfimationNumber>CV34573XTW2122</ConfimationNumber> */}
        <Paragraph>
          You will be receiving an email with your reservation details and
          payment confirmation. If you have questions or would like to modify
          your reservation you can send an email to susanisabell@yahoo.com or
          call us at 479-640-4467.
        </Paragraph>
        <Buttons>
          <Button blue to="/">
            Finish
          </Button>
        </Buttons>
      </Container>
    )
  }
}

export default withApollo(Complete)

const BlueText = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  font-size: 16px;
  color: ${props => props.theme.mainColor};
  font-style: italic;
  margin-top: 30px;
  line-height: 24px;
`
// const ConfimationNumber = styled.div`
//   font-family: ${props => props.theme.secondaryFont};
//   font-weight: ${props => props.theme.bold};
//   font-size: 20px;
//   color: ${props => props.theme.fontColor};
//   margin-top: 22px;
//   line-height: 24px;
// `
const Paragraph = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.light};
  font-size: 16px;
  color: ${props => props.theme.fontColor};
  margin-top: 24px;
  margin-bottom: 50px;
  line-height: 19px;
`
