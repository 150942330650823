import React from 'react'
import { Switch, Route } from 'react-router'
import { Layout } from 'components/AppLayout'
import Container from 'components/Container'
import Details from './Details'
import GroupInfo from './GroupInfo'
import Submit from './Submit'
import Complete from './Complete'

const steps = [
  {
    title: 'Reservation Details',
    path: '/app/group-reservation'
  },
  {
    title: 'Group Information',
    path: '/app/group-reservation/group-info'
  },
  {
    title: 'Review & Submit',
    path: '/app/group-reservation/submit'
  }
]

export default () => (
  <Layout noaside nonav>
    <Container steps={steps} title="Group Reservation Request">
      <Switch>
        <Route exact path="/app/group-reservation" component={Details} />
        <Route path="/app/group-reservation/group-info" component={GroupInfo} />
        <Route path="/app/group-reservation/submit" component={Submit} />
        <Route path="/app/group-reservation/complete" component={Complete} />
      </Switch>
    </Container>
  </Layout>
)
