import React from 'react'
import styled from 'styled-components'
import ProgressIndicator from './ProgressIndicator'

export default ({ title, steps, currentStepNbr, children }) => (
  <Container>
    <Card>
      <Header>
        {steps && (
          <ProgressIndicator steps={steps} currentStepNbr={currentStepNbr} />
        )}
        <Title>{title}</Title>
      </Header>
      <Content>{children}</Content>
    </Card>
  </Container>
)

export { default as Buttons } from './Buttons'

const Container = styled.div`
  display: flex;
  justify-content: center;
`
const Card = styled.div`
  flex-basis: 760px;
  margin: 60px 40px;
  background-color: ${props => props.theme.backgroundColor};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  @media (max-width: ${props => props.theme.desktop}px) {
    margin-top: 50px;
    max-width: none;
    width: 100%;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    margin: 10px;
  }
`
const Header = styled.div`
  width: 100%;
  padding: 0 80px;
  background-color: white;
  border-radius: 15px 15px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.mainFont};
  font-weight: ${props => props.theme.bold};

  @media (max-width: ${props => props.theme.desktop}px) {
    padding: 0 30px;
  }
`
const Title = styled.h1`
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 34px;
  line-height: 42px;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.mainFont};
  font-weight: ${props => props.theme.bold};

  @media (max-width: ${props => props.theme.desktop}px) {
    height: 80px;
    font-size: 26px;
    line-height: 26px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 0 80px 30px;

  @media (max-width: ${props => props.theme.desktop}px) {
    padding: 0 30px 30px;
  }

  @media (max-width: ${props => props.theme.phone}px) {
    padding: 0 15px 30px;
  }
`
