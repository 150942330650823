import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { ReactComponent as RemoveIcon } from 'icons/remove.svg'

import { LineItem, Name, Price } from './styles'

const AccommodationLineItem = ({ removeAccommodation, id, name, price }) => (
  <LineItem>
    <Name>{name}</Name>
    <RightDiv>
      <Price>${price}</Price>
      <Remove onClick={() => removeAccommodation({ variables: { id } })} />
    </RightDiv>
  </LineItem>
)

const REMOVE_ACCOMMODATION = gql`
  mutation updateAccommodation($id: ID) {
    addRemoveAccommodation(id: $id) @client
  }
`

export default graphql(REMOVE_ACCOMMODATION, { name: 'removeAccommodation' })(
  AccommodationLineItem
)

const RightDiv = styled.div`
  display: flex;
  align-items: center;
`

const Remove = styled(RemoveIcon)`
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-left: 10px;
  transition: 0.5s;

  &:hover {
    stroke: red;
  }
`
