import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { transparentize } from 'polished'
import ImageLoader from '@merchantlabs/gatsby-image-loader'

export default ({ children, fluid, ...restProps }) => (
  <StaticQuery
    query={graphql`
      query BodyImageQuery {
        imageSharp(original: { src: { regex: "/picnic-table/" } }) {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={data => (
      <ImageLoader sizes={fluid ? fluid : data.imageSharp.fluid}>
        {({ src }) => (
          <Container image={src} {...restProps}>
            {children}
          </Container>
        )}
      </ImageLoader>
    )}
  />
)

const Container = styled.main`
  grid-area: content;
  background: linear-gradient(
      to right,
      ${transparentize(0.65, '#2794F2')},
      ${transparentize(0.65, '#2794F2')}
    ),
    url(${props => props.image});
  background-size: cover;
  background-attachment: fixed;

  @media (max-width: ${props => props.theme.tablet}px) {
    margin-bottom: ${props => (props.nonav ? 0 : '60px')};
  }
`
