import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import MapQuery from './MapQuery'
import Img from 'gatsby-image'
import Button from 'components/Button'

import { ReactComponent as X } from 'icons/x-icon.svg'

export default class Map extends Component {
  state = {
    showMap: false
  }

  toggleMap = () => this.setState({ showMap: !this.state.showMap })

  render() {
    const { showMap } = this.state
    return (
      <MapQuery>
        {image => (
          <Fragment>
            <ToggleButton blue to="#" onClick={this.toggleMap}>
              View Map
            </ToggleButton>
            {showMap ? (
              <Modal>
                <Close onClick={this.toggleMap} />
                <MapContainer>
                  <Img sizes={image.fluid} />
                </MapContainer>
              </Modal>
            ) : null}
          </Fragment>
        )}
      </MapQuery>
    )
  }
}
const ToggleButton = styled(Button)`
  min-width: 160px;
  background-color: transparent;
  border: 1px solid #32394b;
  color: #32394b;

  &:hover {
    color: white;
    background-color: #32394b;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    margin-top: 20px;
  }
`
const Close = styled(X)`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 20px;
  cursor: pointer;

  path {
    stroke: white;
    stroke-width: 2px;
  }
`
const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 5% 5%;
  animation: openMap 0.5s;

  @keyframes openMap {
    from {
      opacity: 0;
      transform: scale(1.025);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`
const MapContainer = styled.div`
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.45);
`
