import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  Elements,
  CardElement,
  injectStripe
} from 'react-stripe-elements-universal'

const elementStyle = {
  base: {
    color: '#32325D',
    fontWeight: 500,
    fontFamily: 'Inter UI, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#CFD7DF'
    }
  },
  invalid: {
    color: '#E25950'
  }
}

class PaymentForm extends Component {
  static propTypes = {
    getToken: PropTypes.func.isRequired
  }

  state = { token: '' }

  onBlur = async () => {
    const { stripe, name, getToken } = this.props

    const { token } = name
      ? await stripe.createToken({ name })
      : await stripe.createToken()

    if (token) {
      this.setState({ token })
      getToken('stripeToken', token.id)
    }
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <StyledCardElement onBlur={this.onBlur} style={elementStyle} />
      </div>
    )
  }
}

const StripeForm = injectStripe(PaymentForm)

export default props => (
  <Elements>
    <StripeForm {...props} />
  </Elements>
)

const StyledCardElement = styled(CardElement)`
  display: block;
  margin: 10px 0 20px 0;
  max-width: 400px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0 1px 0;
  border-radius: 4px;
  background: white;

  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0 1px 3px;
    transition: all 150ms ease;
  }

  & * {
    font-family: Inter UI, Open Sans, Segoe UI, sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  & form {
    max-width: 496px !important;
    padding: 0 15px;
  }

  & form > * + * {
    margin-top: 20px;
  }

  & .container {
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 3px;
  }

  & fieldset {
    border-style: none;
    padding: 5px;
    margin-left: -5px;
    margin-right: -5px;
    background: rgba(18, 91, 152, 0.05);
    border-radius: 8px;
  }

  & fieldset legend {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: #8898aa;
    padding: 3px 10px 7px;
  }

  & .card-only {
    display: block;
  }
  & .payment-request-available {
    display: none;
  }

  & fieldset legend + * {
    clear: both;
  }

  & input,
  & button {
    appearance: none;
    outline: none;
    border-style: none;
    color: #fff;
  }

  & input::-webkit-input-placeholder {
    color: #9bacc8;
  }

  & input::-moz-placeholder {
    color: #9bacc8;
  }

  & input:-ms-input-placeholder {
    color: #9bacc8;
  }

  & input:-webkit-autofill {
    transition: background-color 100000000s;
  }

  & button {
    display: block;
    width: 100%;
    height: 37px;
    background-color: #d782d9;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
  }

  & button:active {
    background-color: #b76ac4;
  }

  & .error svg .base {
    fill: #e25950;
  }

  & .error svg .glyph {
    fill: #f6f9fc;
  }

  & .error .message {
    color: #e25950;
  }

  & .success .icon .border {
    stroke: #ffc7ee;
  }

  & .success .icon .checkmark {
    stroke: #d782d9;
  }

  & .success .title {
    color: #32325d;
  }

  & .success .message {
    color: #8898aa;
  }

  & .success .reset path {
    fill: #d782d9;
  }
`
