import React from 'react'
import { Field } from 'formik'
import styled from 'styled-components'

export default ({ error, touched, className, ...restProps }) => (
  <div className={className}>
    <Input {...restProps} />
    <ErrorMessage>{touched && error && error}</ErrorMessage>
  </div>
)

const Input = styled(Field)`
  width: 100%;
  padding: 10px;
  margin: 10px 0 0;
  background: white;
  border-radius: 4px;
  color: ${props => props.theme.fontColor};
  box-shadow: rgba(50, 50, 93, 0.14902) 0 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0 1px 0;
  font-family: Inter UI, Open Sans, Segoe UI, sans-serif;
  font-size: 16px;
  font-weight: 300;
  caret-color: ${props => props.theme.mainColor};
  outline: none;

  &::placeholder {
    color: #515e80;
    opacity: 0.3;
    transition-duration: 0.3s;
  }

  &:focus {
    &::placeholder {
      color: transparent;
    }
  }

  @media (max-width: ${props => props.theme.phone}px) {
    width: 100%;
  }
`
export const ErrorMessage = styled.div`
  color: red;
  height: 14px;
  margin: 5px 0 0;
  font-size: 14px;
  padding-left: 5px;
  min-width: 200px;
`
