import React from 'react'
import { Switch, Route } from 'react-router'

import Manage from './manage'
import Request from './request'

export default () => (
  <Switch>
    <Route path="/app/group-reservation/manage" component={Manage} />
    <Route path="/app/group-reservation" component={Request} />
  </Switch>
)
