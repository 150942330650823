import React from 'react'
import styled from 'styled-components'

import { ReactComponent as fire } from 'icons/campfire-icon.svg'

export default () => (
  <Container>
    <CampFire />
    <Text>Please finish selecting dates to view available accommodations.</Text>
  </Container>
)
const Container = styled.div`
  padding: 30px 50px;
  width: 80%;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  margin: 40px 0 80px;
`
const Text = styled.h2`
  margin: 30px 0;
  font-size: 20px;
  line-height: 26px;
  color: #898a9c;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.light};
  text-align: center;
`
const CampFire = styled(fire)`
  width: 80px;
  height: 80px;
  margin: 30px 0 0;
`
