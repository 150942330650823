import React from 'react'
import styled from 'styled-components'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import AccommodationTile from './AccommodationTile'
import Unavailable from './Unavailable'
import Error from './Error'
import Loading from './Loading'
import NoDatesSelected from './NoDatesSelected'

const AVAILABLE_ACCOMMODATIONS = gql`
  query AvailableAccommodations($arriveDate: String!, $leaveDate: String!) {
    accommodations(arriveDate: $arriveDate, leaveDate: $leaveDate) {
      ...AccommodationTileFragment
    }
  }
  ${AccommodationTile.fragment}
`

const DATES = gql`
  query {
    pickerRange @client {
      arriveDate
      leaveDate
    }
  }
`

export default () => (
  <AccommodationsList>
    <Query query={DATES}>
      {({ data: { pickerRange } }) => {
        if (!pickerRange.leaveDate) return <NoDatesSelected />
        return (
          <Query query={AVAILABLE_ACCOMMODATIONS} variables={pickerRange}>
            {({ data, error, loading }) => {
              if (loading) return <Loading />
              if (error) return <Error />
              if (data.accommodations.length === 0) return <Unavailable />
              return data.accommodations.map((accommodation, i) => (
                <AccommodationTile key={i} {...accommodation} />
              ))
            }}
          </Query>
        )
      }}
    </Query>
  </AccommodationsList>
)

const AccommodationsList = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
