import React from 'react'
import styled from 'styled-components'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

const ADD_REMOVE_ACCOMMODATION = gql`
  mutation updateAccommodation($id: ID) {
    addRemoveAccommodation(id: $id) @client
  }
`

export default ({ isReserved, id, className }) => (
  <Mutation mutation={ADD_REMOVE_ACCOMMODATION} variables={{ id }}>
    {(updateAccommodation, result) => (
      <Button
        color={isReserved ? 'red' : 'blue'}
        onClick={() => updateAccommodation()}
        className={className}
      >
        {isReserved ? 'Remove' : 'Reserve'}
      </Button>
    )}
  </Mutation>
)

const Button = styled.div`
  width: 108px;
  height: 35px;
  font-size: 16px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  color: white;
  background: ${props =>
    props.color === 'blue' ? props.theme.mainColor : 'red'};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background-color: ${props =>
      props.color === 'blue' ? '#0a6dc3' : 'darkred'};
  }
`
