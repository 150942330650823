import React from 'react'
import styled from 'styled-components'

export default () => (
  <Container>
    <SVG
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 32.6 40"
    >
      <path
        id="Path_111"
        style={{ fill: '#604439' }}
        d="M29.5,39.9L1.3,32.5c-0.5-0.1-1-0.5-1.1-1.1l0,0c-0.5-1.5-0.1-3.1,1.1-4.1l0,0
      c0.4-0.4,1-0.5,1.5-0.3L31,34.5c0.5,0.1,1,0.5,1.1,1.1l0,0c0.5,1.5,0.1,3.1-1.1,4.1l0,0C30.6,40,30.1,40.1,29.5,39.9z"
      />
      <path
        id="Path_112"
        style={{ fill: '#7F5F4F' }}
        d="M29.8,39.7L1.6,32.3c-0.5-0.1-1-0.5-1.1-1.1l0,0c-0.5-1.5-0.1-3.1,1.1-4.1l0,0
        c0.4-0.4,1-0.5,1.5-0.3l28.2,7.4c0.5,0.1,1,0.5,1.1,1.1l0,0c0.5,1.5,0.1,3.1-1.1,4.1l0,0C30.9,39.7,30.3,39.8,29.8,39.7z"
      />
      <path
        id="Path_113"
        style={{ fill: '#604439' }}
        d="M3,39.9l28.2-7.4c0.5-0.1,1-0.5,1.1-1.1l0,0c0.5-1.5,0.1-3.1-1.1-4.1l0,0
          c-0.4-0.4-1-0.5-1.5-0.3L1.6,34.4c-0.5,0.1-1,0.5-1.1,1.1l0,0C0,37,0.4,38.6,1.6,39.6l0,0C1.9,40,2.5,40.1,3,39.9z"
      />
      <path
        id="Path_114"
        style={{ fill: '#604439' }}
        d="M15.4,35.9l11.2-3l-11.2-3l-11.2,3L15.4,35.9z"
      />
      <path
        id="Path_115"
        style={{ fill: '#7F5F4F' }}
        d="M2.9,39.6l28.2-7.4c0.5-0.1,1-0.5,1.1-1.1l0,0c0.5-1.5,0.1-3.1-1.1-4.1l0,0
            c-0.4-0.4-1-0.5-1.5-0.3L1.5,34.1c-0.5,0.1-1,0.5-1.1,1.1l0,0c-0.5,1.5-0.1,3.1,1.1,4.1l0,0C1.8,39.6,2.4,39.7,2.9,39.6z"
      />
      <Flame2
        id="Path_116_1_"
        d="M8.3,21c1.6,5.8,4.4,7,8.4,7s6.6-2.5,7.8-6.2c2.8-8.5-1.7-13-1.6-14.4
                c-0.3,0.8,0.5,6.4,0.5,7.3c0,0.5-0.2,0.9-0.5,1.2c-0.5,0.4-1.2,0.3-1.6-0.3c-0.3-1-0.8-3-1.2-4.6c-0.3-1.5-1.6-2.9-2.4-4.7
                c-0.8-1.8-1.2-2.8-1-4.9c0,0-3.6,3.8-3.8,6.4c-0.2,2.5,1,6,0,7.6s-2.1,0.6-2.6-1.1c-0.5-1.8-0.7-4.6-0.2-6.4
                C10.2,7.9,6.7,15.4,8.3,21z M13.6,20.4c1.7-2.7,3.4-4.6,3.4-6.4c-0.1,2.3,0.5,4.3,1.1,6c0.6,1.7,0.5,5.1-2.7,4.6
                C13.9,24.3,11.9,23,13.6,20.4z"
      />
      <Flame1
        id="Path_116_3_"
        d="M23.5,8.4c0.6,1.8-0.2,9.7-1.8,10.1c-2.2,1.1-2.5-1.7-1.6-4.5
                  c0.6-1.9,0.2-5.1,0-7.6C19.9,3.8,15.8,0,15.8,0c0.2,2.1-0.2,5.1-0.4,7.1c-0.2,2-1.5,4-1.9,5.5c-0.4,1.6-0.6,1.4-1,2.4
                  c-0.4,0.6-1.2,0.7-1.8,0.3c-0.4-0.3-0.6-0.7-0.6-1.2c0-0.9,2.2-7.7,1.8-8.5c0.1,1.4-8.3,6.2-5,14.7c1.4,3.6,4.3,6.2,8.9,6.2
                  s7.8-1.2,9.6-7C27.3,14,23.5,8.4,23.5,8.4z M16.2,23.6c-2.3,0.1-2-3.7-1.3-5c0.7-1.5,1.1-3.4,1-5.3c0,1.5,0.8,3.7,1.7,6
                  C18.6,21.5,17.6,23.6,16.2,23.6z"
      />
      <Flame6
        id="Path_116_1_"
        d="M8.3,21c1.6,5.8,4.4,7,8.4,7s6.6-2.5,7.8-6.2c2.8-8.5-1.7-13-1.6-14.4
                        c-0.3,0.8,0.5,6.4,0.5,7.3c0,0.5-0.2,0.9-0.5,1.2c-0.5,0.4-1.2,0.3-1.6-0.3c-0.3-1-0.8-3-1.2-4.6c-0.3-1.5-1.6-2.9-2.4-4.7
                        c-0.8-1.8-1.2-2.8-1-4.9c0,0-3.6,3.8-3.8,6.4c-0.2,2.5,1,6,0,7.6s-2.1,0.6-2.6-1.1c-0.5-1.8-0.7-4.6-0.2-6.4
                        C10.2,7.9,6.7,15.4,8.3,21z M13.6,20.4c1.7-2.7,3.4-4.6,3.4-6.4c-0.1,2.3,0.5,4.3,1.1,6c0.6,1.7,0.5,5.1-2.7,4.6
                        C13.9,24.3,11.9,23,13.6,20.4z"
      />
    </SVG>
    <Loading>Loading...</Loading>
  </Container>
)
const Container = styled.div`
  padding: 40px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const Loading = styled.h1`
  margin: 30px 0 30px 30px;
  font-size: 34px;
  letter-spacing: -1px;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.mainFont};
  font-weight: ${props => props.theme.bold};
  max-width: 700px;
  text-align: center;
`
const SVG = styled.svg`
  width: 100px;
  height: 100px;
  enable-background: new 0 0 32.6 40;
`
const Flame = styled.path`
  fill: #ff5b1f;
`
const Flame1 = styled(Flame)`
  opacity: 0;
  animation: flame1 0.5s infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);

  @keyframes flame1 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
const Flame2 = styled(Flame)`
  animation: flame2 0.5s infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);

  @keyframes flame2 {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
const Flame6 = styled(Flame)`
  opacity: 0;
  animation-delay: 0.2s;
  animation: flame2 0.5s infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
`
