import React from 'react'
import styled from 'styled-components'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import ProgressBar from './ProgressBar'
import moment from 'moment'

import {
  Details,
  Subtitle,
  Accomodations,
  LineItem,
  Name,
  Price,
  Disclaimer,
  Guests,
  Total,
  TotalPrice,
  GuestCount,
  Dates
} from './styles'

const steps = [
  { title: 'Accommodations', path: '/app/reservation' },
  { title: 'Waiver', path: '/app/reservation/waiver' },
  { title: 'Payment', path: '/app/reservation/checkout' }
]

const GET_RESERVATION_INPUT = gql`
  query {
    reservationInput @client {
      guestCount
      liabilityRelease
      accommodations {
        name
        price
      }
      includedGuestCount
      additionalGuestFee
      totalPrice
    }
    pickerRange @client {
      arriveDate
      leaveDate
    }
  }
`

const formatDate = date => (date ? moment(date).format('LL') : null)

export default () => (
  <Query query={GET_RESERVATION_INPUT}>
    {({ data: { reservationInput, pickerRange }, loading }) => {
      if (loading) return null
      return (
        <Details>
          <ProgressBar steps={steps} />
          <Dates>
            {formatDate(pickerRange.arriveDate)} -{' '}
            {formatDate(pickerRange.leaveDate)}
          </Dates>
          <Subtitle>
            Accommodations ({reservationInput.accommodations.length})
          </Subtitle>
          <Accomodations>
            {reservationInput.accommodations.map((accomodation, i) => (
              <LineItem key={i}>
                <Name>{accomodation.name}</Name>
                <Price>${accomodation.price}</Price>
              </LineItem>
            ))}
          </Accomodations>
          <Subtitle>Guest count</Subtitle>
          <Disclaimer>
            Includes <TempText>{reservationInput.includedGuestCount}</TempText>{' '}
            guests. Each additional is <em> $3/person</em>.
          </Disclaimer>
          <Guests>
            <GuestCount>{reservationInput.guestCount}</GuestCount>
          </Guests>
          <LineItem>
            <Name>Additional Guest Charge</Name>
            <Price>${reservationInput.additionalGuestFee}</Price>
          </LineItem>
          <Total>
            Total
            <TotalPrice>
              <TempTotal>${reservationInput.totalPrice}</TempTotal>
            </TotalPrice>
          </Total>
        </Details>
      )
    }}
  </Query>
)

const TempText = styled.p`
  display: inline-block;
  font-family: ${props => props.theme.secondaryFont};
  font-size: 14px;
  font-weight: ${props => props.theme.regular};
  margin-top: 13.5px;
  font-style: italic;
  color: ${props => props.theme.mainColor};
`

const TempTotal = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 20px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.mainColor};
  width: 100%;
`
