import React from 'react'
import { Switch, Route } from 'react-router'
import { Layout, Aside } from 'components/AppLayout'
import Details from './ReservationDetails'
import LiabilityWaiver from './LiabilityWaiver'
import Payment from './Payment'
import Confirmation from './Confirmation'
import Cancel from './Cancel'

export default () => (
  <Layout nonav>
    <Aside title="Reservation Details">
      <Details />
    </Aside>
    <Switch>
      <Route path="/app/reservation/waiver" component={LiabilityWaiver} />
      <Route path="/app/reservation/checkout" component={Payment} />
      <Route path="/app/reservation/confirmation" component={Confirmation} />
      <Route path="/app/reservation/cancel" component={Cancel} />
    </Switch>
  </Layout>
)
