import React, { Component } from 'react'
import styled from 'styled-components'
import Button from 'components/Button'

export default class Error extends Component {
  reload = () => {
    if (typeof window !== 'undefined') {
      window.location.reload()
    }
  }

  render() {
    return (
      <Container>
        <Text>This is awkward. It looks like something went wrong.</Text>
        <Button onClick={this.reload} blue component="button">
          Try Again
        </Button>
      </Container>
    )
  }
}
const Container = styled.div`
  padding: 30px 50px;
  width: 80%;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  margin: 40px 0 80px;
`
const Text = styled.h2`
  margin: 20px 0;
  font-size: 20px;
  line-height: 26px;
  color: #898a9c;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.light};
  text-align: center;
`
