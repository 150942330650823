import React, { Fragment, Component } from 'react'
import styled from 'styled-components'

import { ReactComponent as DownArrow } from 'icons/arrow.svg'
import { ReactComponent as Logo } from 'icons/campfire-icon.svg'
import { ReactComponent as ExitIcon } from 'icons/x-icon.svg'

export default class ReservationDetails extends Component {
  state = { open: false }

  toggleDropdown = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const { children, title } = this.props
    const { open } = this.state
    return (
      <Fragment>
        <MobileOpener>
          <DetailsTitle>View Details</DetailsTitle>
          <Arrow onClick={this.toggleDropdown} open={open} />
        </MobileOpener>
        <DetailsContainer open={open}>
          <DetailsHeading>
            <CampLogo />
            <DetailsTitle>{title}</DetailsTitle>
            <Exit onClick={this.toggleDropdown} open={open} />
          </DetailsHeading>
          {children}
        </DetailsContainer>
      </Fragment>
    )
  }
}

const MobileOpener = styled.div`
  grid-area: details;
  position: sticky;
  display: none;
  padding: 0 30px;
  width: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  height: 50px;
  transition-duration: 0.5s;
  z-index: 51;
  overflow: hidden;

  @media (max-width: ${props => props.theme.tablet}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const DetailsContainer = styled.aside`
  grid-area: aside;
  position: fixed;
  padding: 0 30px;
  width: 420px;
  right: 0;
  top: 70px;
  bottom: 0;
  background-color: white;

  @media (max-width: ${props => props.theme.desktop}px) {
    width: 340px;
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    display: ${props => (props.open ? 'block' : 'none')};
    top: 0;
    padding: 0 20px;
    width: 100%;
    background-color: white;
    height: 100vh;
    transition-duration: 0.5s;
    z-index: 51;
    overflow: hidden;
    animation: showDetails 0.5s;

    @keyframes showDetails {
      from {
        opacity: 0;
        transform: scale(0.975);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`
const DetailsHeading = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${props => props.theme.tablet}px) {
    justify-content: space-between;
  }
`
const DetailsTitle = styled.h2`
  font-size: 20px;
  line-height: 20px;
  color: ${props => props.theme.fontColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
`
const CampLogo = styled(Logo)`
  display: none;
  width: 45px;
  height: 40px;

  @media (max-width: ${props => props.theme.tablet}px) {
    display: inline-block;
  }
`
const Arrow = styled(DownArrow)`
  display: none;
  width: 24px;
  height: 15px;

  @media (max-width: ${props => props.theme.tablet}px) {
    display: inline-block;
    transform: rotate(180deg);
  }
`
const Exit = styled(ExitIcon)`
  display: none;
  width: 20px;
  height: 20px;

  path {
    stroke-width: 2pt;
    stroke-linecap: round;
    stroke: ${props => props.theme.mainColor};
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    display: inline-block;
  }
`
