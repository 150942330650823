import styled from 'styled-components'

export default styled.div`
  margin: 30px 0 0;
  width: 100%;
  padding-top: 30px;
  border-top: 1px solid #d5dceb;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`
