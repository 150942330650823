import React from 'react'

export default ({ steps, currentStep = 1, containerWidth }) => {
  const myArray = steps.slice(0, currentStep)
  const stepLabel = steps.slice(currentStep - 1)[0]

  // svg vars
  const containerHeight = 60
  const width = containerWidth - 60
  const height = 10
  const offsetX = (containerWidth - width) / 2
  const offsetY = 40

  const startPoint = offsetX + height / 2
  const endPoint = offsetX + width - height / 2
  const diff = (endPoint - startPoint) / 3

  const textContainerWidth = stepLabel.length * 6.5 + 12
  const textContainerHeight = 20
  const fontSize = 11

  return (
    <svg
      style={{ fill: '#2794F2' }}
      width={containerWidth}
      height={containerHeight}
    >
      <rect
        width={width}
        height={height}
        x={offsetX}
        y={offsetY}
        rx={height / 2}
        ry={height / 2}
        style={{
          fill: '#FFF',
          strokeWidth: 0.75,
          stroke: '#D5DCEB'
        }}
      />
      <circle cy={offsetY + height / 2} cx={startPoint} r="2" />
      <circle cy={offsetY + height / 2} cx={startPoint + diff} r="2" />
      <circle cy={offsetY + height / 2} cx={startPoint + diff + diff} r="2" />
      <circle cy={offsetY + height / 2} cx={endPoint} r="2" />
      <rect
        width={currentStep * (width / 3)}
        x={offsetX}
        y={offsetY}
        rx={height / 2}
        ry={height / 2}
        height={height}
        style={{ strokeWidth: 0.75, stroke: '#2794F2' }}
      />
      {myArray.map((e, i) => (
        <circle
          key={i}
          cy={offsetY + height / 2}
          cx={diff * (i + 1) + startPoint}
          r="8"
          style={{ strokeWidth: 2, stroke: '#FFF' }}
        />
      ))}
      <g
        transform={`translate(
          ${currentStep * diff + startPoint - textContainerWidth / 2},
          ${offsetY - height - textContainerHeight}
        )`}
        width={textContainerWidth}
        height={textContainerHeight + 5}
      >
        <rect width={textContainerWidth} height={textContainerHeight} rx="5" />
        <polygon
          transform={`translate(
            ${textContainerWidth / 2 - 5},
            ${textContainerHeight}
          )`}
          points="5 5,10 0,0 0"
        />
        <text
          transform={`translate(
            ${textContainerWidth / 2},
            ${textContainerHeight / 2 + 1}
          )`}
          alignmentBaseline="middle"
          textAnchor="middle"
          style={{
            fill: '#FFF',
            fontSize: `${fontSize}px`,
            fontFamily: 'Roboto-Bold, Roboto',
            fontWeight: 700
          }}
        >
          {stepLabel}
        </text>
      </g>
    </svg>
  )
}
