import React, { Fragment, Component } from 'react'
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import Container from 'components/Container'
import Button from 'components/Button'
import { Buttons } from 'components/Container'
import Waiver from './Waiver'
import Checkbox from 'components/Checkbox'
import {
  Paragraph,
  ModalContainer,
  Text,
  AgreeTerms,
  BlueText,
  SignatureTitle,
  Input
} from './styles'

class Liability extends Component {
  state = {
    showModal: false
  }

  toggleModal = () => this.setState({ showModal: !this.state.showModal })

  render() {
    const { showModal } = this.state
    const {
      data: { reservationInput },
      updateLiabilityRelease
    } = this.props
    return (
      <Fragment>
        {showModal && (
          <ModalContainer>
            <Waiver toggleModal={this.toggleModal} />
          </ModalContainer>
        )}
        <Container title="Release of Liability">
          <Formik
            initialValues={{
              ...reservationInput,
              signature: ''
            }}
            validationSchema={yup.object().shape({
              signature: yup
                .string('Please sign your name')
                .required('Please sign your name')
            })}
            render={({ values, errors, touched, isValid }) => (
              <Form>
                <Paragraph>
                  Each person that visits Camp Terra Rose is asked to sign a
                  release of liability. By checking the box below you agree to
                  accept the terms laid out in our <em>Liability Waiver.</em>
                </Paragraph>
                <Button blue component="button" onClick={this.toggleModal}>
                  View Waiver
                </Button>
                <AgreeTerms>
                  <Checkbox
                    checked={reservationInput.liabilityRelease}
                    onClick={() =>
                      updateLiabilityRelease({
                        variables: {
                          liabilityRelease: !reservationInput.liabilityRelease
                        }
                      })
                    }
                  />
                  <Text>
                    I agree to accept the terms laid out in the{' '}
                    <em>Liability Waiver.</em>
                  </Text>
                </AgreeTerms>
                <BlueText>
                  By signing your name below you are acknowledging that you are
                  releasing Camp Terra Rose from all liablility in the event of
                  injury or death.
                </BlueText>
                <SignatureTitle>Sign Here</SignatureTitle>
                <Input
                  name="signature"
                  placeholder="First & Last Name"
                  error={errors.signature}
                  touched={touched.signature}
                />
                <Buttons>
                  <Button blue to="/app/accommodations">
                    Back
                  </Button>
                  <Button
                    to={
                      isValid && reservationInput.liabilityRelease
                        ? '/app/reservation/checkout'
                        : '#'
                    }
                    blue={isValid && reservationInput.liabilityRelease}
                    disabled={!isValid || !reservationInput.liabilityRelease}
                  >
                    Next
                  </Button>
                </Buttons>
              </Form>
            )}
          />
        </Container>
      </Fragment>
    )
  }
}

const UPDATE_LIABILITY_RELEASE = gql`
  mutation updateReservationInput($liabilityRelease: Boolean) {
    updateReservationInput(liabilityRelease: $liabilityRelease) @client {
      liabilityRelease
    }
  }
`
const GET_RESERVATION_INPUT = gql`
  query {
    reservationInput @client {
      liabilityRelease
    }
  }
`
export default compose(
  graphql(UPDATE_LIABILITY_RELEASE, { name: 'updateLiabilityRelease' }),
  graphql(GET_RESERVATION_INPUT)
)(Liability)
