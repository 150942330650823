import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'

import ImageCarousel from './ImageCarousel'
import Modal from './Modal'
import AddRemoveButton from './AddRemoveButton'

class AccommodationTile extends Component {
  state = { showModal: false }

  toggleModal = () => this.setState({ showModal: !this.state.showModal })

  render() {
    const {
      id,
      name,
      includedGuestCount,
      description,
      price,
      images,
      isReserved
    } = this.props
    const { showModal } = this.state
    return (
      <Fragment>
        <Modal
          id={id}
          name={name}
          price={price}
          images={images}
          includedGuestCount={includedGuestCount}
          description={description}
          isOpen={showModal}
          isReserved={isReserved}
          closeModal={this.toggleModal}
        />
        <Container>
          <ImageCarousel images={images} />
          <TopBanner>
            <Text>
              <Dollar>$</Dollar>
              <Price>{price}</Price>
              <PerNight>/night</PerNight>
            </Text>
            <AddRemoveButton id={id} isReserved={isReserved} />
          </TopBanner>
          <BottomBanner>
            {name}
            <MoreInfoButton onClick={this.toggleModal}>
              MORE INFO
            </MoreInfoButton>
          </BottomBanner>
        </Container>
      </Fragment>
    )
  }
}

AccommodationTile.fragment = gql`
  fragment AccommodationTileFragment on Accommodation {
    id
    name
    description
    price
    includedGuestCount
    isReserved @client
    images {
      small {
        url
        width
        height
      }
      large {
        url
        width
        height
      }
      full {
        url
        width
        height
      }
    }
  }
`

export default AccommodationTile

const Container = styled.div`
  position: relative;
  flex: 320px;
  min-height: 350px;
  margin: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  @media (max-width: ${props => props.theme.phone}px) {
    margin: 10px 0;
  }
`
const TopBanner = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  justify-content: space-between;
`
const Text = styled.div`
  display: flex;
`
const Dollar = styled.div`
  font-size: 17px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  font-style: italic;
  color: white;
`
const Price = styled.div`
  font-size: 35px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  font-style: italic;
  color: white;
`
const PerNight = styled.div`
  font-size: 14px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  font-style: italic;
  color: white;
  padding-left: 3px;
`
const BottomBanner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  font-size: 20px;
  padding: 0 15px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
`

const MoreInfoButton = styled.div`
  width: 110px;
  height: 30px;
  font-size: 13px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  border: 1px solid ${props => props.theme.fontColor};
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.3s;

  &:hover {
    color: white;
    background-color: ${props => props.theme.fontColor};
  }
`
