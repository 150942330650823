import React from 'react'
import styled from 'styled-components'

export default ({
  arriveDate,
  departureDate,
  groupName,
  guests,
  contact,
  activities,
  totalPrice
}) => (
  <Details>
    <Date>
      {arriveDate} - {departureDate}
    </Date>
    <Subtitle>Group Name</Subtitle>
    <Name>{groupName} Guests</Name>
    <Subtitle>Guest count</Subtitle>
    <Name>{guests.total} Guests</Name>
    <Subtitle>Primary Contact</Subtitle>
    <Name>{contact.name}</Name>
    <ContactInfo>{contact.email}</ContactInfo>
    <ContactInfo>{contact.phone}</ContactInfo>
    <Subtitle>Activities ({activities.length})</Subtitle>
    <Activities>
      {activities.map((activity, i) => (
        <Name key={i}>{activity.activity}</Name>
      ))}
    </Activities>
    <Total>
      Total
      <TotalPrice>${totalPrice}</TotalPrice>
    </Total>
  </Details>
)

const Details = styled.div`
  max-width: 500px;
  margin: 0 auto;
`
const Date = styled.div`
  font-family: ${props => props.theme.secondaryFont};
  font-size: 20px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  margin-top: 15.5px;
  margin-bottom: 22px;
  font-style: italic;
`
const Subtitle = styled.div`
  font-family: ${props => props.theme.labelFont};
  font-size: 16px;
  font-weight: ${props => props.theme.regular};
  color: #aab4cb;
  margin-top: 24px;
  padding-bottom: 6.5px;
  border-bottom: solid 1px #d5dceb;
`
const Activities = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22.5px;
  margin-bottom: 8px;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  box-shadow: inset 0 -10px 25px -10px rgba(0, 0, 100, 0.1);
`
const Name = styled.div`
  margin: 10px 0 0;
  font-family: ${props => props.theme.secondaryFont};
  font-size: 16px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  font-style: italic;

  &:first-of-type {
    margin-top: 0;
  }
`
const ContactInfo = styled.div`
  margin: 10px 0 0;
  font-family: ${props => props.theme.secondaryFont};
  font-size: 16px;
  font-weight: ${props => props.theme.light};
  color: #aab4cb;
  font-style: italic;

  &:first-of-type {
    margin-top: 0;
  }
`
const Total = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  align-items: flex-end;
  font-family: ${props => props.theme.secondaryFont};
  font-size: 20px;
  font-weight: ${props => props.theme.bold};
  color: ${props => props.theme.fontColor};
  margin-top: 30px;
  width: 100%;
  padding-top: 15px;
  border-top: solid 1px #d5dceb;
`
const TotalPrice = styled.div`
  color: ${props => props.theme.mainColor};
  font-size: 26px;
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};
`
