import React from 'react'
import styled from 'styled-components'
import { Carousel } from '@merchantlabs/react-carousel'

export default ({ images }) => (
  <Carousel buttonComponent={ArrowButton} dotComponent={Carousel.Dot}>
    {images.map((image, i) => <Pic key={i} src={image.large.url} />)}
  </Carousel>
)

const ArrowButton = Carousel.Button.extend`
  background: rgba(0, 0, 0, .4);

  &:hover {
    background: rgba(0, 0, 0, .6);
  }

  @media (max-width: ${props => props.theme.tablet}px) {
    display: none;
  }
`

const Pic = styled.div`
  min-height: 280px;
  background-color: #f4f4fa;
  background-image: url(${prop => prop.src});
  background-size: cover;
  background-position: center;
`
