import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { Buttons } from 'components/Container'
import Button from 'components/Button'
import DefaultInput from 'components/Input'

class Review extends Component {
  render() {
    const {
      data: { groupReservationInput, pickerRange },
      updateGroupInfo,
      submitRequest
    } = this.props

    const {
      additionalInfo,
      __typename,
      ...restInputData
    } = groupReservationInput
    const { __typename: type, ...dates } = pickerRange

    return (
      <Formik
        initialValues={{ additionalInfo }}
        validationSchema={yup.object().shape({
          additionalInfo: yup.string()
        })}
        onSubmit={values => updateGroupInfo({ variables: values })}
        render={({ values, errors, touched }) => (
          <Fragment>
            <Container>
              <Text>Any additional requests or information?</Text>
              <Textbox
                name="additionalInfo"
                component="textarea"
                placeholder="Response"
                error={errors.additionalInfo}
                touched={touched.additionalInfo}
              />
              <SmallText>
                Please submit your large group reservation request by clicking
                “Submit Request” below. After submitting, a member of our staff
                will contact you with pricing and payment information.
              </SmallText>
            </Container>
            <Buttons>
              <Button to="/app/group-reservation/group-info" blue>
                Back
              </Button>
              <Button
                onClick={() => {
                  submitRequest({
                    variables: {
                      input: {
                        ...restInputData,
                        ...dates,
                        ...values
                      }
                    }
                  })
                }}
                to="/app/group-reservation/complete"
                blue
              >
                Submit Request
              </Button>
            </Buttons>
          </Fragment>
        )}
      />
    )
  }
}

const UPDATE_GROUP_INFO = gql`
  mutation updateGroupInfo($additionalInfo: String) {
    updateClientGroupReservation(additionalInfo: $additionalInfo) @client
  }
`

const SUBMIT_REQUEST = gql`
  mutation submitRequest($input: GroupReservationInput) {
    createGroupReservation(input: $input) {
      id
    }
  }
`

const GROUP_RESERVATION_INPUT = gql`
  query {
    pickerRange {
      arriveDate
      leaveDate
    }
    groupReservationInput {
      guestCount
      contactName
      contactEmail
      contactPhone
      groupName
      reservationPurpose
      activities
      additionalInfo
    }
  }
`

export default compose(
  graphql(UPDATE_GROUP_INFO, { name: 'updateGroupInfo' }),
  graphql(SUBMIT_REQUEST, { name: 'submitRequest' }),
  graphql(GROUP_RESERVATION_INPUT)
)(Review)

const Container = styled(Form)`
  width: 100%;
  height: 100%;
`
const Text = styled.p`
  margin: 20px 0 0;
  font-size: 20px;
  line-height: 26px;
  color: ${props => props.theme.fontColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.bold};

  @media (max-width: ${props => props.theme.tablet}px) {
    font-size: 18px;
    line-height: 20px;
  }
`
const SmallText = styled.p`
  margin: 5px 0;
  font-size: 16px;
  line-height: 24px;
  font-style: italic;
  color: ${props => props.theme.mainColor};
  font-family: ${props => props.theme.secondaryFont};
  font-weight: ${props => props.theme.regular};
`
const Textbox = styled(DefaultInput)`
  & > textarea {
    width: 100%;
    height: 80px;
    resize: none;
    border: none;
    box-sizing: border-box;
    line-height: 20px;
  }
`
